.category-banner {
    text-align: center;
    margin: 20px 0;
  }
  
  .category-banner img {
    border-radius: 8px;
  }

  @media screen and (max-width : 768px) {
    .category-banner img {
        max-width: 90%;
        height: 120px;
        border-radius: 8px;
      }
  }
.product-img {
  text-align: center;
  position: relative;
}

.product-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.product-wish-icon {
  position: absolute;
  top: 3px;
  right: 3px;
}

.product-item {
  /* border: 1px solid var(--primary_color);  */
  padding: 20px 20px 10px 10px;
  border-radius: 5px;
  width: 190px;
  margin-bottom: 20px;
  margin-right: 12px;
  border: 0.5px solid #e8e8e8;
}

.product-item:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.timer {
  background-color: #f8f8f8;
  font-size: 9px;
  font-weight: 700;
  display: flex;
  gap: 5px;
  max-width: fit-content;
  padding: 0px 5px;
  border-radius: 4px;
  align-items: center;
  text-transform: uppercase;
}

.product-item-mobile {
  min-width: 100%;
  cursor: pointer;
  background: rgb(255, 255, 255);
  border: 0.5px solid #e8e8e8;
  box-shadow: rgba(0, 0, 0, 0.04) 2px 2px 8px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.product-mobile-para {
  overflow: hidden;
  color: rgb(31, 31, 31);
  font-weight: 600;
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
  width: 100%;
  height: 36px;
}

.product-item .name {
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: normal;
  height: 49px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
}

.product-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
}

p.produc-price {
  padding: 0;
  text-decoration: line-through;
  color: rgb(153, 153, 153);
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.product-weight {
  margin: 5px auto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

p.price {
  text-decoration: none;
  font-weight: bold;
  padding: 0;
  margin: 0px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
}

.offer-price {
  margin-top: 4px;
  font-size: 9px;
  font-weight: 700;
  padding: 1px 4px;
  color: white;
  background-color: rgb(83, 140, 238);
  border-radius: 4px;
  width: fit-content;
}

.p-price {
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
}

.p-btn {
  border-radius: 8px;
  background: #f7fff9;
}

.address-container {
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.btn.btn-success {
  background-color: var(--primary_color);
  color: white;
  border: 1px solid var(--primary_color);
}

.btn.btn-cart {
  width: 66px;
  height: 31px;
  border: 1px solid var(--primary_color);
  color: var(--primary_color);
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-img img {
  max-width: 100%;
  border-radius: 5px;
  margin: auto;
  width: 140px;
  height: 140px;
}

/* .slick-slider .slick-prev:before, .slick-slider .slick-next:before {
    color: var(--primary_color);
    font-size: 30px;
    margin-left: -5px;
}  */

.slider-container {
  position: relative;
}

.grid_6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.grid_5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.slick-arrow,
.left,
.right {
  width: 34px !important;
  height: 34px !important;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px;
  border-radius: 50%;
  position: absolute !important;
  z-index: 2;
  border: 4px solid transparent !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left {
  left: 0;
  top: 40%;
}

.right {
  right: 45px;
  top: 40%;
}

.slick-next:before {
  content: "" !important;
  position: absolute;
  right: 10px;
  top: 9px;
  transform: rotate(315deg);
  width: 9px;
  height: 9px;
  border-right: 2px solid rgb(28, 28, 28);
  border-bottom: 2px solid rgb(28, 28, 28);
  border-top: 0px;
  border-left: 0px;
  font-size: 13.5px;
  color: rgb(105, 105, 105);
  font-weight: bold;
}

.slick-prev:before {
  content: "" !important;
  border-right: 2px solid rgb(28, 28, 28);
  border-bottom: 2px solid rgb(28, 28, 28);
  border-top: 0px;
  border-left: 0px;
  font-size: 13.5px;
  color: rgb(105, 105, 105);
  font-weight: bold;
  position: absolute;
  right: 7px;
  top: 9px;
  transform: rotate(135deg);
  width: 9px;
  height: 9px;
}

.products-container {
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
}

.Selectbar.value {
  width: 50px;
}

.view-all {
  text-decoration: none;
  font-size: 20px;
  color: var(--primary_color);
  font-weight: 400;
  margin-right: 10px;
  line-height: 32px;
}

.catagery-items-grid .product-item {
  background: #fff;
}

.catagery-items-grid {
  padding: 30px 0px 12px 12px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-right: 0px;
}

.view-all:hover {
  text-decoration: underline;
}

h2 {
  margin-bottom: 10px;
}

img.cart-img {
  max-width: 30px;
}

.btn-cart-added {
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
  background: var(--primary_color) !important;
  color: #fff;
}

.qty {
  flex: 1;
  color: white;
}

.custom-plus,
.custom-minus {
  position: relative;
  width: 14px;
  height: 14px;
}

.custom-plus::before,
.custom-plus::after,
.custom-minus::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10%;
  width: 60%;
  height: 2px;
  border-radius: 1px;
  background-color: #fff;
}

.custom-plus::after {
  transform: rotate(90deg);
}

button.btn-minus {
  flex: 1;
  padding: 0px;
  background: none;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  justify-content: center;
}

.btn-cart-added:hover,
.btn-cart-added:active {
  color: #fff !important;
}

.product-detail {
  padding: 0px 12px;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.product-detail-info {
  padding: 48px;
  position: sticky;
  top: 86px;
}

.pdfl {
  display: flex;
}

.pdfl-left {
  flex: 1.2 1;
}

.pdfl-right {
  flex: 1;
  border-left: 1px solid #ddd;
}

.all-by-brand {
  color: green;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.product-detail-img img {
  width: 323px;
  height: 382px;
}

.product-detail-img {
  text-align: center;
  padding: 40px;
}

.product-detail .p-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.product-detail .produc-price {
  font-weight: bold;
}

.product-detail-actions .product-actions {
  display: flex;
  width: 90px;
  float: right;
}

.product-description {
  padding-right: 60px;
  padding-bottom: 60px;
}

.product-description .desc-details {
  color: #666666;
}

.product-description .desc-details {
  color: #666666;
}

p.why-title {
  font-size: 16px;
  font-weight: 500;
  color: rgb(51, 51, 51);
  margin-top: 24px;
  margin-bottom: 8px;
}

.why-cards img {
  padding-bottom: 8px;
  width: 100%;
}

.why-cards p {
  font-size: 12px;
  /* margin: 0px; */
}

.why-bold-text {
  color: #000;
  line-height: 14px;
  margin-bottom: 4px;
}

.why-text-color {
  color: #666;
  line-height: 14px;
}

.product-description .desc-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 32px;
  margin-bottom: 16px;
}

.product-description-mobile {
  display: none;
}

.discount_icon_container {
  background-image: url(./images/discount_sticker.webp);
  background-repeat: no-repeat;
  background-size: 28px 29px;
  position: absolute;
  top: 0px;
  left: 22px;
  z-index: 1;
}

.discount_percentage {
  font: Roboto;
  font-weight: 800;
  line-height: 9.38px;
  font-size: 9px;
  color: #ffffff;
  height: 29px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.slick-slide {
  padding-bottom: 10px;
  position: relative;
}
.custom-message {
  background-color: #000000a3;
  color: #f7fff9;
  text-align: center;
  z-index: 100;
  font-size: 12px;
  padding: 10px;
  display: block;
  width: auto;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  overflow: visible;
}
@media screen and (max-width: 767.98px) {
  .product-img img {
    max-width: 100%;
  }

  .grid_6,
  .grid_5 {
    grid-template-columns: repeat(3, 1fr);
  }

  .all-by-brand {
    font-size: 14px;
  }
}

@media screen and (max-width: 767.98px) {
  .slick-slider .slick-next:before {
    margin-left: 0px;
  }

  .slick-next {
    right: -10px;
    z-index: 9;
  }

  .slick-prev {
    left: -10px;
    z-index: 9;
  }

  .view-all {
    font-size: 13px;
    font-weight: 600;
    margin-right: 14px;
  }

  .catagery-items-grid {
    padding: 8px;
    grid-template-columns: auto auto;
  }

  .product-img img {
    width: 120px;
    height: 120px;
  }

  .product-item {
    max-width: 137px;
    /* margin: auto; */
    padding: 20px 10px 10px 10px;
    /* margin-right: 5px; */
  }

  .display-categories .product-item {
    max-width: 160px;
  }

  .btn.btn-cart {
    width: 60px;
    font-size: 12px;
    font-weight: 600;
  }

  .product-detail .p-title {
    font-size: 18px;
  }

  .pdfl {
    flex-direction: column;
  }

  .product-detail-img img {
    max-width: 100%;
  }

  .product-description {
    display: none;
  }

  .product-description-mobile {
    display: block;
    padding-top: 40px;
  }

  .product-detail-info {
    padding: 12px;
  }

  .product-detail-actions .product-actions {
    width: 60px;
  }

  .pdfl-right {
    border: none;
  }
}

@media screen and (max-width: 375px) {
  .catagery-items-grid {
    grid-template-columns: auto auto;
  }
}

.variant-container {
  margin-top: 30px;
}

.variant-type {
  font-size: 14px;
  margin-top: 2px;
  font-weight: 500;
  color: #878787;
  width: 110px;
  min-width: 110px;
}

.variant {
  display: flex;
  margin-bottom: 15px;
}

.variant-type-container {
  display: flex;
}

.variant-type-item {
  margin: 0 12px 4px 0;
  font-weight: bold;
  border: 2px solid #ddd;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.variant-type-item:hover {
  border: 2px solid #a72b89;
  cursor: pointer;
}

.selected {
  border: 2px solid #a72b89;
}

.nav-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
}

@media screen and (max-width: 900px) {
  .product-description-mobile {
    display: block;
    padding-top: 40px;
  }

  .product-description-mobile .desc-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1024px) {
  .next-arrow {
    position: absolute;
    right: 40px;
    top: 50%;
  }
}

@media screen and (min-width: 376px) and (max-width: 768px) {
  .next-arrow {
    position: absolute;
    right: 38px;
    top: 50%;
  }

  .catagery-items-grid {
    grid-template-columns: auto auto auto;
  }

  .custom-message {
    font-size: 12px;
    padding: 8px;
  }
}

@media screen and (max-width: 425px) {
  .next-arrow {
    position: absolute;
    right: 31px;
    top: 50%;
  }
  .grid_6,
  .grid_5 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 375px) {
  .next-arrow {
    position: absolute;
    right: 13px;
    top: 50%;
  }
}

@media screen and (max-width: 320px) {
  .next-arrow {
    position: absolute;
    right: 4px;
    top: 50%;
  }
}

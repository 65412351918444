.footer-container .inner {
  border-top: 1px solid green;
  padding: 30px 0px;
}

.footer-container {
  padding-top: 60px;
}

.logo-footer img {
  width: 213px;
}

.address-footer {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.footer-container .inner {
  border-top: 1px solid green;
  padding: 30px 0px;
}

.footer-container {
  padding-top: 60px;
}

.logo-footer img {
  width: 200px;
}

.footer-content {
  max-width: 340px;
}

.footer-links a {
  color: black;
  text-decoration: none;
  font-weight: 400;
  font-size: 15px;
}

.site-links li a {
  color: #666666;
  text-decoration: auto;
  font-size: 14px;
}

.site-links li {
  margin-bottom: 2px;
}

.site-links {
  margin-bottom: 40px;
}

.brand-footer {
  padding: 16px 0px;
  background-color: #fcfcfc;
}

p.copyright {
  color: #666666;
  font-size: 12px;
  margin: 0;
}

p.downloadapp {
  font-size: 14px;
  color: #666666;
}

p.text-powered {
  font-size: 14px;
  color: #666666;
  text-align: center;
  margin: 12px;
}

p.text-powered a {
  font-size: 14px;
  color: #666666;
  text-decoration: auto;
}

.soc-icon {
  font-size: 30px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.social-icons {
  display: flex;
  justify-content: space-around;
  max-width: 130px;
  margin: auto;
  gap: 20px;
}

.downloadapp img {
  width: 92px;
  margin-left: 20px;
}

.site-links h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
}

.footer-bottom a {
  color: #fff;
  font-weight: bold;
}

.btn-ft {
  background-color: var(--primary_color);
}

.newsletter-heading h3 {
  text-decoration: underline;
  margin-bottom: 20px;
  font-size: 26px;
}

.footer-storys {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}

.footer-input {
  width: 461px;
  height: 60px;
  background: #f0f7f4;
  border-radius: 24px;
  border-right: none;
}

.btn-footer {
  width: 156px;
  height: 60px;
  background: var(--primary_color);
  border-radius: 16px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  border-left: none;
}

.footer-payment {
  margin-top: 25px;
}

.btn-footer:hover {
  width: 156px;
  height: 60px;
  background: #ffffff;
  border-radius: 16px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #3bb77e;
  border: 1px solid #3bb77e;
}

.payment-img {
  width: 399px;
  height: 75px;
}

.Footer-Categories {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  letter-spacing: 0.05em;
}

.footer-cat-text {
  font-size: 0.875rem;
  line-height: 1.125rem;
  /* font-weight: 500!important; */
}
.footer-cat-text {
  list-style: none;
}
.location-item {
  border-right: 1px solid #ccc;
  padding: 5px 10px;
  flex: 0 0 16.66%;
  margin: 10px 0px;
  box-sizing: border-box;
}
.location-item:last-child {
  border-right: none;
}
.see-more {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}
.see-more:hover {
  text-decoration: none;
}
@media only screen and (max-width: 991.98px) {
}

@media screen and (max-width: 767.98px) {
  .site-links li {
    margin-bottom: 6px;
  }

  .site-links h5 {
    margin: 24px 0px;
  }

  p.downloadapp {
    display: block;
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .downloadapp img {
    display: block;
    text-align: center;
    margin: auto;
    margin-top: 16px;
  }

  .brand-footer {
    padding-bottom: 26px;
    background-color: #fcfcfc;
  }

  .footer-container {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 80px;
  }

  p.copyright {
    margin-bottom: 10px;
  }
}

.grid_3_2 {
    display: grid;
    grid-template-columns: 3fr 2fr;
    column-gap: 85px;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 27px;
}

.grid-3 img {
    box-shadow: 0px 7px 12px 0px #00000063;
    height: 272px;
    width: 215px;
    border-radius: 10px;
}

.about-py {
    padding: 50px 0px;
}

.about-py h1{
    margin-bottom: 16px;
}

.about-img {
    box-shadow: 0px 7px 17px 0px #00000040;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary_color);
    padding: 50px 25px;
}

.desc-main{
    padding: 50px 0px;
    display: flex;
    justify-content: center;
}

.about-desc {
    max-width: 1092px;
    min-height: 311px;
    border-radius: 15px;
    text-align: center;
    padding: 56px 157px;
    background-color: var(--secondary_color);
}

.about-desc p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: center;
}

.list-btn-parent {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    margin-top: 34px;
    padding: 0px
}

.list-btn {
    background-color: #EA001B;
    width: 350px;
    box-shadow: 0px 7px 8px 0px #00000040;
    border-radius: 6px;
    color: white;
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 6px;
    min-height: 87px;
    padding: 20px 50px;
    text-align: left;
}

.list-btn svg{
    flex-shrink: 0;
}

.about-desc img {
    width: 39px;
    margin-bottom: 32px;
}

.red-title {
    color: #EA001B;
    font-weight: 500;
    font-size: 15px;
    line-height: 18.15px;
}
/* 
.breadcrumb-section{
    background-color: var(--primary_color);
    height: 280px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
} */

@media screen and (max-width: 767.98px) {
    .grid_3_2 {
        grid-template-columns: 1fr;
    }

    .grid-3 {
        grid-template-columns: 1fr;
    }

    .grid-3 img {
        margin: 20px auto;
    }

    .about-desc {
        padding: 56px 0px;
    }
}
.back-to-orders {
    border: 1.5px solid #f9f9f9;
    border-radius: 0.2rem;
    padding: 0.5rem;
    color: black;
    position: absolute;
    margin: 0;
    box-shadow: 2px 2px 2px grey;
}

.order-content {
    margin: 2rem 4rem;
    text-align: center;
}

.order-status {
    color: var(--primary_color);
    font-size: 40px;
    font-weight: 600;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.order-status-mobile {
    color: var(--primary_color);
    font-size: 30px;
    font-weight: 600;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.pay-mode {
    /* background-color: #f8f8f8; */
    border: 1px solid #04B700;
    border-radius: 40px;
    margin: 1.5rem 4rem;
}

.order-summary {
    border: 1px solid #f8f8f8;
    border-radius: 1rem;
    margin: 1.5rem 4rem;
}

.download-icon{
    color: var(--primary_color);
}

.order-id{
    color: var(--primary_color);
}

.order-bill-details {
    margin: 1.5rem 4rem;
}

.download-summary {
    text-decoration: none;
    color: green;
}

.order-item-image {
    width: 4rem;
    height: 4rem;
}

.order-saving {
    color: white;
    border: 1px solid green;
    background-color: var(--primary_color);
    border-radius: 0.2rem;
}

.customer-support {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: var(--primary_color);
    margin: 1.5rem 4rem;
    border-radius: 0.2rem;
}


@media screen and (max-width: 767.98px) {
    .pay-mode {
        margin: 1.5rem 2rem;
    }
    .order-bill-details{
        margin: 1.5rem 2rem;
    }
    .order-summary{
        margin: 1.5rem 2rem;
    }
    .customer-support{
        margin: 1.5rem 2rem;
    }
}
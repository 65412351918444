.product-grid-container {
    display: grid;
    grid-template-columns: 6fr 6fr; /* Adjusted for 6fr 6fr layout */
    gap: 10px;
    /* padding: 20px; */
    margin: 0px 60px;
  }
  
  .left-banner, .right-banner {
    display: grid;
    grid-template-columns: 6fr 6fr; /* Split banner into two equal parts */
    gap: 10px;
  }
  
  .left-banner-img img, .right-banner-img img {
    width: 100%;
    height: 560px;
    border-radius: 10px;
  }
  
  .left-banner-data, .right-banner-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Create 2 columns for small cards */
    /* gap: 5px; */
  }
  
  .product-card {
    background: #fff;
    border-radius: 10px;
    width: 100%;
    height: 140px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .product-card img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  
  .right-products .product-card button {
    background: blue;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  .btn-plus {
    border: none;
    background-color: #3CAC47;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .product-grid-container {
      grid-template-columns: 1fr;
    }
  
    .left-banner, .right-banner {
      grid-template-columns: 1fr;
    }
  
    .left-banner-data, .right-banner-data {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .left-banner, .right-banner {
      grid-template-columns: 1fr;
    }
  
    .left-banner-data, .right-banner-data {
      grid-template-columns: repeat(2, 1fr); /* Ensure 2 columns on smaller screens */
    }
  
    .product-card img {
      width: 80px;
      height: 80px;
    }
  }
  img {
    cursor: pointer;
  }
  
.banner-img img {
    max-width: calc(100% - 30px);
    border-radius: 10px;
}
.banners-container {
    padding: 15px 0px;
}

@media screen and (max-width: 767.98px) {
    .banner-img img {
        max-width: 100%;
    } 
    .slick-slider .slick-prev:before, .slick-slider .slick-next:before{
        margin-left: 5px;
    }
}
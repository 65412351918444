.navbar-brand img {
  max-width: 180px;
  margin-left: 20px;
  max-height: 56px;
}
a.navbar-brand {
  padding: 0;
}
.navbar-bottom {
  margin-top: 86px;
}
.navbar-container .navbar {
  background: #fff;
  border-bottom: 1px solid #eeeeee;
  height: 65px;
}
.fixed-top {
  top: 40px;
}
.grocery-essential {
  width: 100vw;
  background-color: var(--primary_color);
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  height: 40px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  z-index: 20;
}
.grocery-essential-ul {
  display: flex;
  align-items: center;
  gap: 18px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.grocery-essential-li {
  display: flex;
  align-items: center;
}
.grocery-essential-para {
  font-size: 0.75rem;
  line-height: 1rem;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 10px;
}
.grocery-essential-data {
  color: #ffffff;
  font-size: 12px;
  text-decoration: none;
}
.icon {
  margin-right: 5px;
  height: 20px;
  width: 20px;
  /* display: inline-flex; */
}
.v-divider::before {
  height: 65px;
  background: #eeeeee;
  width: 1px;
  position: absolute;
  content: "";
  top: -32px;
}

.v-divider {
  position: relative;
  margin-left: 10px;
  margin-right: 48px;
}
.cart-container {
  display: flex;
  min-width: 112px;
  height: 50px;
  /* height:52px; */
  -webkit-box-pack: center;
  -webkit-box-align: center;
  align-items: center;
  background: var(--primary_color);
  /* margin-right: 20px; */
}
.cart-icon-holder {
  font-size: 28px;
  margin-right: 8px;
  flex: 1;
}
.cart-qty p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}
.cart-qty {
  flex: 3;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}
.cart-container:hover {
  background-color: #fff;
  color: var(--primary_color);
  border: 1px solid var(--primary_color);
}
.show-tablet {
  display: none;
}

.popup-cart.zindex-0 {
  z-index: 0;
}
.popup-cart .modal-content {
  border-radius: 0;
  height: 100vh;
}

.popup-cart.modal.show .modal-dialog {
  position: fixed;
  right: 0;
  top: 0;
  margin: 0;
}

.modal-title.h4 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}
.modal-tile-back.modal-title.h4 {
  color: var(--primary_color);
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
}
.btn-proceed {
  width: 100%;
  background: var(--primary_color);
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.btn-proceed p {
  margin-bottom: 0;
  font-size: 16px;
}
.btn-proceed:hover,
.btn-proceed:active {
  background: #fff;
  color: var(--primary_color);
  border: 1px solid var(--primary_color);
}
p.p-qty {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}
.total-text {
  font-size: 11px;
  font-weight: 400;
}
p.p-pay {
  position: relative;
  margin-right: 20px;
}
p.p-pay::after {
  content: "";
  color: white;
  width: 10px;
  height: 10px;
  border-top: 1px solid white;
  position: absolute;
  top: 5px;
  transform: rotate(45deg);
  border-right: 1px solid white;
}
p.p-delivery-time {
  font-weight: 700;
  margin-bottom: 0;
}
.delivery-location {
  flex: 1;
  width: 100%;
}
svg.home-add {
  font-size: 40px;
}
.delivery-location p {
  margin-bottom: 0px;
}
.del-address {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}
p.add-type {
  font-weight: 700;
}
img.cart-display-img {
  max-width: 100%;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 8px;
}
.popup-cart .cart-modal-rn {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  background-color: rgb(245, 247, 253);
}
.popup-cart .cart-modal-address {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  background-color: rgb(245, 247, 253);
}
p.cart-item-price {
  font-weight: 700;
}
.productItem p {
  margin-bottom: 10px;
}
.productItem {
  margin-bottom: 20px;
}
p.cart-total-price {
  font-weight: 700;
}
.search-form button {
  background: no-repeat;
}
.search-form input {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.search-form button {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
}
.search-form {
  flex: 1;
}
.dropdown-item {
  margin-bottom: 2px;
  color: #666;
  font-size: 14px;
}
.show-search {
  display: flex;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  color: rgb(102, 102, 102);
  margin-top: 200px;
  font-size: 44px;
}

@media screen and (max-width: 991.98px) {
  .show-tablet {
    display: block;
  }
  .show-desktop {
    display: none;
  }
  .cart-container {
    position: fixed;
    z-index: 9;
    bottom: 10px;
    width: calc(100% - 20px);
    display: flex;
    left: 10px;
    right: 10px;
  }
  .popup-cart .cart-modal-rn {
    height: calc(100vh - 250px);
    overflow-y: scroll;
    background-color: rgb(245, 247, 253);
  }
  .popup-cart .cart-modal-address {
    height: calc(100vh - 200px);
    overflow-y: scroll;
    background-color: rgb(245, 247, 253);
  }
}
.cart-modal-rn {
  overflow-y: scroll;
  background-color: rgb(245, 247, 253);
}
.cart-delviry-rn {
  background-color: rgb(255, 255, 255);
}
.cart-modal-rn-address {
  min-width: 400px;
  height: calc(100vh - 150px);
  background-color: rgb(245, 247, 253);
}
.cart-modal-rn-address-mobile {
  height: calc(100vh - 200px);
  background-color: rgb(245, 247, 253);
}
.nav-locat-icon {
  text-align: center;
  font-size: 30px;
  color: rgb(102, 102, 102);
}
.form-nav-input {
  width: 100%;
  margin-right: 10px;
  max-width: 1090px;
}

.form-search input {
  padding-left: 50px;
}

.form-search .search-icon {
  top: 5px;
}
form.form-nav-input a {
  text-decoration: auto;
}
.form-controll {
  border: 1px solid black;
  border-radius: 5px;
  padding: 12px;
  margin: 15px;
}

.form-controll > input,
.form-control > input {
  width: 90%;
  border: none;
  margin-left: 30px;
}

.form-controll > input:focus,
.form-control > input:focus {
  border: none;
  outline: none;
}

.search-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 12px;
  top: 8px;
  color: black;
}
.search-div input {
  border-radius: 12px;
  background: rgb(248, 248, 248);
  color: rgb(130, 130, 130);
  height: 48px;
}

.placeholderEle {
  padding-left: 45px;
  color: #666;
}
.login-btn {
  font-size: 18px;
  font-weight: 400;
  color: rgb(54, 54, 54);
  padding: 0px;
}
.location-nav-main {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  /* align-items: center; */
  display: flex;
  font-size: 16px;
  flex-direction: column;
  cursor: pointer;
  width: 250px;
}
.location-title {
  font-family: "Roboto", sans-serif;
  color: rgb(0, 0, 0);
  font-weight: 800;
  /* margin-bottom: 6px; */
  font-size: 18px;
}
.location-name {
  font-size: 13px;
  color: rgb(0, 0, 0);
  font-weight: 400;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
  max-width: 210px;
  text-overflow: ellipsis;
}
.empty-img {
  max-width: 100%;
  width: 144px;
  height: 144px;
  overflow: hidden;
}
.Empty-cart {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background-color: white;
  padding: 16px;
}
.navBar-mobile-main {
  position: sticky;
  top: 0px;
  width: 100%;
  background: #fff;
  z-index: 99;
}
.nav-mobile-header {
  height: auto;
  padding-bottom: 12px;
}
.nav-mobile-location {
  align-items: center;
  display: flex;
  width: auto;
  justify-content: space-between;
  margin-bottom: 10px;
}
.nav-mobile-time {
  color: rgb(0, 0, 0);
  font-weight: 800;
  margin-bottom: 0px;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
}
.nav-mobile-store {
  font-size: 13px;
  color: rgb(0, 0, 0);
  font-weight: 400;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: auto;
  max-width: 156px;
  text-overflow: ellipsis;
}
.account-mobile {
  width: 32px;
  height: 32px;
}

.account-mobile-nav {
  background-color: #fff;
}
.account-number {
  width: 100%;
  background-color: rgb(255, 255, 255);
}
.user-account-number {
  padding: 16px;
}
.user-account-number-text {
  font-size: 16px;
  font-family: Okra;
  color: rgb(102, 102, 102);
}
.account-details {
  width: 100%;
  background-color: rgb(255, 255, 255);
}
.account-padding {
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
}
.account-info {
  font-size: 12px;
  margin-bottom: 8px;
  color: rgb(153, 153, 153);
  display: block;
}
.order-histoy {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}
.order-icon {
  color: rgb(102, 102, 102);
  background-color: rgb(247, 247, 247);
  margin-right: 16px;
  border-radius: 12px;
  font-size: 24px;
}
.icon-name {
  font-size: 16px;
  color: black;
  display: inline-block;
}

.loginfooter.modal-footer {
  display: initial;
  text-align: center;
}
.nav-mobile-location1 {
  align-items: center;
  display: flex;
  width: auto;
  gap: 10px;
}
.loginfooter.modal-footer p {
  font-size: 12px;
  color: #666666;
}
.loginfooter.modal-footer a {
  font-size: 12px;
  color: #3faa4a;
}

.leftarroworder {
  font-size: 30px;
}
.p-avalibile {
  color: rgb(0, 0, 0);
  font-size: 12px;
  line-height: 24px;
}
.not-avalible {
  color: rgb(0, 0, 0);
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
}
.min-value-error {
  color: orange;
  font-weight: bold;
}
.search-mdicon {
  width: 30px;
  height: 30px;
  left: 12px;
  top: 8px;
  color: black;
}
.nav-mobile-common {
  display: flex;
  align-items: center;
  padding: 12px;
}
.navmbf1 {
  flex: 1;
}

.navmbf2 {
  flex: 6;
}

.navmbf3 {
  flex: 1;
}
.login-head__text {
  color: #333;
  font-size: 21px;
  line-height: normal;
  margin: 0;
  text-align: center;
}
.Selectbar {
  font-size: 14px;
  font-weight: 500;
  border-color: #ddd;
  border-radius: 3px;
  border-width: 1px;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #3faa4a;
}
.weight--light {
  font-weight: 200 !important;
}
.login-head {
  padding: 42px 24px 0;
  display: none;
}
.login-header-main {
  text-align: center;
  padding: 20px 0;
  display: block;
}
.loginfooter {
  text-align: center;
  display: block;
}
.loginfooter a {
  font-size: 12px;
  color: #3faa4a;
  margin-right: 10px;
}
.login-help {
  display: block;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}
.icon-info {
  width: 15px;
  height: 15px;
  margin-left: 6px;
  cursor: pointer;
  transition: color 0.3s;
}
.icon-info:hover {
  background-color: #0000003b;
}
.modal-footer-viewCart {
  padding: 10px;
  color: #318616;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s;
}
#contained-modal-title-vcenter {
  font-size: 16px;
  color: #1f1f1f;
}
.modal-body-viewCart h2 {
  font-size: 18px;
  letter-spacing: 1px;
  word-spacing: 1px;
}
.modal-body-viewCart p {
  font-size: 13px;
  letter-spacing: 1px;
  word-spacing: 1px;
}
.weight--semibold {
  font-weight: 500 !important;
}

/* Number input */
.num-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.num-input[type=number] {
  -moz-appearance: textfield;
}

.input-prefix{
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 10px;
}

.num-input{
  border: none;
  outline: none !important;
}


@media only screen and (max-width: 991.98px) {
}
@media screen and (max-width: 767.98px) {
  .fade.login-popup.modal.show .modal-content {
    height: 100vh;
    width: 100vw;
    border-radius: 0px;
    border: none;
  }

  .popup-cart.modal.show .modal-dialog {
    z-index: -1;
  }

  .popup-cart.modal.show .modal-footer {
    position: fixed;
    bottom: 0rem;
    width: 375px;
    z-index: 100;
    background: white;
  }

  .popup-cart .modal-content {
    width: 375px;
  }
  .modal-dialog.modal-dialog-centered {
    margin: 0;
    max-width: 100%;
  }
  .modal-dialog.modal-dialog-centered .modal-header {
    border-bottom: none;
  }
  .modal-dialog.modal-dialog-centered .modal-header .modal-title.h4 {
    display: none;
  }
  .modal-dialog.modal-dialog-centered
    .modal-header
    .modal-tile-back.modal-title.h4 {
    display: block;
  }
  form.login-form {
    padding: 20px 0px;
  }
  .search-div input {
    width: 100%;
  }
  .search-mobile-main {
    padding-left: 12px;
    padding-right: 12px;
  }
  .login-head__text {
    color: #333;
    font-size: 21px;
    line-height: normal;
    margin: 0;
    text-align: center;
  }
  .weight--light {
    font-weight: 200 !important;
  }
  .login-head {
    padding: 42px 24px 0;
    display: block;
  }
  .login-header-main {
    text-align: center;
    padding: 20px 0;
    display: block;
  }
  .loginfooter {
    text-align: center;
    display: block;
  }
  .loginfooter a {
    font-size: 12px;
    color: #3faa4a;
    margin-right: 10px;
  }
  .login-help {
    display: block;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
  }
  .weight--semibold {
    font-weight: 500 !important;
  }
  .loginfooter.modal-footer {
    display: none;
  }
  .nav-mobile-location {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 12px;
  }
  .form-controll {
    border: 1px solid #ddd;
    padding: 8px;
  }
  .form-controll .search-icon {
    left: 22px;
    width: 25px;
    height: 25px;
  }
}

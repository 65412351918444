.wishlist-product {
    padding: 12px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}

.wishlist-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.nav-heart-icon {
    width: 40px;
    height: 40px;
    color: var(--primary_color)
}

.icon-wishlist svg {
    width: 20px;
    height: 20px;
    float: right;
    /* position: absolute; */
    right: 2px;
    top: 2px;
    color: var(--primary_color)
}

.product-item {
    position: relative;
}
form.otp-form {
  text-align: center;
  padding: 20px 0;
}
.fullCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}
h4.login-success {
  background-color: var(--primary_color);
  color: #fff;
  padding: 60px 20px;
  text-align: center;
}
.form-control.otp-input {
  width: 50px;
  height: 50px;
  border: solid 1px var(--primary_color);
  border-radius: 5px;
  margin: 0 10px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  display: flex;
}

/* Reset and basic styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.image-container {
  display: grid;
  grid-template-columns: 2fr 1fr; /* Large image on left, two smaller on right */
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  width: 100vw;
  max-width: 1200px;
  height: 100vh;
  margin: auto;
  padding: 20px;
}

/* Image Styling */
.image-container img {
  width: 100%;
  height: 100%;

  border-radius: 10px;
}

/* Image Positioning */
.a1 {
  grid-row: span 2; /* Large left-side image */
}

.a2,
.a3 {
  grid-column: 2;
  align-self: center;
  justify-self: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .image-container {
    grid-template-columns: 1fr; /* Switch to single column */
    grid-template-rows: auto;
    height: auto;
  }

  .a1 {
    grid-row: auto;
  }

  .a2,
  .a3 {
    grid-column: auto;
    margin: 16px 0;
  }
}

@media (max-width: 768px) {
  .image-container {
    gap: 5px;
    padding: 10px;
  }

  .image-container img {
    padding: 2px;
  }
}

@media (max-width: 480px) {
  .image-container {
    width: 100%;
    padding: 5px;
  }

  .a2,
  .a3 {
    margin: 10px 0;
  }
}
